import '../styles/cleverreach.scss'

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('[data-cleverreach-popup]').forEach((el) => {
        const id = el.getAttribute('data-cleverreach-popup');

        if (localStorage.getItem(`crpopup${id}`) === '1') {
            return;
        }

        const overlay = document.createElement('div');
        overlay.classList.add('cleverreach-popup');

        const closeBtn = document.createElement('button');
        closeBtn.type = 'button';
        closeBtn.classList.add('close');

        el.prepend(closeBtn);
        overlay.append(el);
        document.body.append(overlay);

        const close = () => {
            localStorage.setItem(`crpopup${id}`, '1');
            overlay.remove();
        };

        closeBtn.addEventListener('click', () => {
            close();
        });
        el.querySelector('form').addEventListener('submit', () => {
            setTimeout(() => {
                close();
            }, 1000)
        })
    })
});
